import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import UserProfilePage from "./components/UserProfilePage";

function App() {
  return (
    <div className="App">
      <UserProfilePage />
    </div>
  );
}

export default App;
