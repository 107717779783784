import React, { useState, useEffect, useRef } from "react";
import Logo from "./Assets/logo.png";
import Oops from "./Assets/Oops.png";
import axios from "axios";
import DeleteSuccessPage from "./DeleteSuccessPage";

const UserProfilePage = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shopName, setShopName] = useState("");
  const [userEmail, setEmail] = useState("");
  const [userPassword, setPassword] = useState("");
  const [message, setMessage] = useState("");
  // const [invalid, setInvalid] = useState(false);
  const closeButtonRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    // console.log(searchParams);
    const shopName = searchParams.get("shop");
    // const shop = `${shopName}.myshopify.com`;
    setShopName(shopName);
    // console.log(shopName);
    if (!shopName) {
      setShowModal(true);
    }
  }, []);

  const handleDeleteAccount = () => {
    const shop = shopName;
    const email = userEmail;
    const password = userPassword;

    axios
      .post("https://app.appokart.com/api/delete-user-by-url", {
        shop,
        email,
        password,
      })
      .then((response) => {
        console.log(response);

        if (response.data.status === 200) {
          onClose();
          closeButtonRef.current.dispatchEvent(new MouseEvent("click"));
          setShowSuccessMessage(true);
        } else if (
          response.data.message === "Store not found with entered shop"
        ) {
          setMessage("Store does not exist!");
        } else if (
          response.data.message === "User not found with entered details"
        ) {
          setMessage("Invalid Email or Password!");
        } else {
          return;
        }
      })
      .catch((error) => {
        // Handle the error
        console.error("Error deleting account:", error);
      });
  };

  const onClose = () => {
    setEmail("");
    setPassword("");
    setMessage("");
  };

  return (
    <>
      {showModal ? (
        <div class="modal show d-block" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Oops! Incorrect URL</h5>
              </div>
              <div class="modal-body">
                <div class="text-center">
                  <img src={Oops} alt="Oops" height="200" />
                  <p class="mt-3">Sorry, the URL you entered is incorrect.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : showSuccessMessage ? (
        <DeleteSuccessPage />
      ) : (
        <div className="container mt-4">
          <div className="d-flex justify-content-center">
            <img
              src={Logo}
              alt="Logo"
              className="mb-3"
              style={{ height: "80px" }}
            />
          </div>
          <div className="card shadow">
            <div className="card-body">
              <h4 class="card-title">Delete Your Account</h4>
              <h6 className="card-title mb-3">Store Name: {shopName}</h6>
              <hr />
              <h6>
                <p>Hello,</p>
                <p>
                  We're sorry to hear that you've decided to delete your
                  account.
                </p>
                <p>We value your presence and appreciate your support.</p>
                <p>
                  If there's anything we can do to improve your experience,
                  please let us know.
                </p>
                <p>
                  We hope you reconsider, but if you still wish to proceed with
                  deleting your account, please provide your email and password
                  for verification purposes.
                </p>
              </h6>
              <hr />

              <div className="text-center">
                <button
                  className="btn btn-danger mt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>

          {/*User Verification Modal */}
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Account Deletion</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                    onClick={onClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <small className="text-danger">{message}</small>
                  <div className="text-center">
                    <input
                      type="store"
                      placeholder="Store"
                      value={shopName}
                      onFocus={() => setMessage("")}
                      onChange={(e) => setShopName(e.target.value)}
                      className="form-control mb-3"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      value={userEmail}
                      onFocus={() => setMessage("")}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control mb-3"
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      value={userPassword}
                      onFocus={() => setMessage("")}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control mb-3"
                    />
                    <button
                      className="btn btn-danger"
                      disabled={userEmail === "" || userPassword === ""}
                      onClick={handleDeleteAccount}
                    >
                      Confirm Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfilePage;
