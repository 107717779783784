import React, { useEffect } from "react";

const DeleteSuccessPage = () => {
  useEffect(() => {
    // Prevent going back to the previous page
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
    };

    return () => {
      // Enable going back when component unmounts
      window.onpopstate = null;
    };
  }, []);

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Account Deleted Successfully!</h1>
      <div className="alert alert-success mt-3 text-center" role="alert">
        Your account has been successfully deleted.
        <br />
        You can now close the tab.
      </div>
    </div>
  );
};

export default DeleteSuccessPage;
